import React from "react"
import {
  FormLayout,
  TextField,
  Button,
  Icon,
  TextStyle,
  ButtonGroup,
  Stack,
  RadioButton,
  Link,
} from "@shopify/polaris"
import {
  TypeMajor,
  TextAlignmentCenterMajor,
  TextAlignmentLeftMajor,
  TextAlignmentRightMajor,
} from "@shopify/polaris-icons"
import update from "immutability-helper"
import styled from "styled-components"

const TextSizeWrapper = styled.div`
  .extraLarge-size svg {
    width: 130%;
    height: 130%;
    max-width: 130%;
    max-height: 130%;
    margin-top: -5px;
  }
  .medium-size .Polaris-Icon {
    padding-top: 2px;
    svg {
      margin-left: 3px;
      width: 90%;
      height: 90%;
      max-width: 90%;
      max-height: 90%;
    }
  }
  .base-size .Polaris-Icon {
    padding-top: 4px;
    svg {
      margin-left: 3px;
      width: 70%;
      height: 70%;
      max-width: 70%;
      max-height: 70%;
    }
  }
  .small-size .Polaris-Icon {
    padding-top: 5px;
    svg {
      margin-left: 4px;
      width: 60%;
      height: 60%;
      max-width: 60%;
      max-height: 60%;
    }
  }
  .extraSmall-size .Polaris-Icon {
    padding-top: 6px;
    svg {
      margin-left: 4px;
      width: 50%;
      height: 50%;
      max-width: 50%;
      max-height: 50%;
    }
  }
`
const colors = [
  {
    value: "none",
    color: "#625A5F",
  },
  {
    value: "success",
    color: "#76A36A",
  },
  {
    value: "info",
    color: "#5E94CD",
  },
  {
    value: "warning",
    color: "#F3D082",
  },
  {
    value: "critical",
    color: "#D24E4C",
  },
  {
    value: "accent",
    color: "#31689A",
  },
  {
    value: "subdued",
    color: "#898688",
  },
]
export default function TextContent({ contentEdit, state, setState }) {
  const content = state.contents[contentEdit]
  return (
    <FormLayout>
      <h6>
        <TextStyle variation="strong">Content text</TextStyle>
      </h6>
      <p style={{marginTop:'-1.5rem'}}>
        You can use markdown such as links.{" "}
        <Link
          external
          url="https://help.upsellplus.com/en/article/how-to-add-links-and-markdown-in-text-customizations-1xoxxi"
          target="_blank"
        >
          Learn more
        </Link>
      </p>
      <TextField
        label="Content text"
        value={content.contentText}
        onChange={(value) => {
          setState(
            update(state, {
              contents: {
                [contentEdit]: { contentText: { $set: value } },
              },
            })
          )
        }}
        multiline={4}
        autoComplete="off"
        labelHidden
      />

      <p>Alignment</p>
      <ButtonGroup segmented>
        <Button
          pressed={"start" === content.alignment}
          onClick={() => {
            setState(
              update(state, {
                contents: {
                  [contentEdit]: { alignment: { $set: "start" } },
                },
              })
            )
          }}
        >
          <Icon source={TextAlignmentLeftMajor} color="base" />
        </Button>
        <Button
          pressed={"center" === content.alignment}
          onClick={() => {
            setState(
              update(state, {
                contents: {
                  [contentEdit]: { alignment: { $set: "center" } },
                },
              })
            )
          }}
        >
          <Icon source={TextAlignmentCenterMajor} color="base" />
        </Button>
        <Button
          pressed={"end" === content.alignment}
          onClick={() => {
            setState(
              update(state, {
                contents: {
                  [contentEdit]: { alignment: { $set: "end" } },
                },
              })
            )
          }}
        >
          <Icon source={TextAlignmentRightMajor} color="base" />
        </Button>
      </ButtonGroup>
      <p>Size</p>
      <TextSizeWrapper>
        <ButtonGroup segmented>
          <div className="extraLarge-size">
            <Button
              pressed={"extraLarge" === content.size}
              onClick={() => {
                setState(
                  update(state, {
                    contents: {
                      [contentEdit]: { size: { $set: "extraLarge" } },
                    },
                  })
                )
              }}
            >
              <Icon source={TypeMajor} color="base" />
            </Button>
          </div>
          <div className="large-size">
            <Button
              pressed={"large" === content.size}
              onClick={() => {
                setState(
                  update(state, {
                    contents: {
                      [contentEdit]: { size: { $set: "large" } },
                    },
                  })
                )
              }}
            >
              <Icon source={TypeMajor} color="base" />
            </Button>
          </div>
          <div className="medium-size">
            <Button
              pressed={"medium" === content.size}
              onClick={() => {
                setState(
                  update(state, {
                    contents: {
                      [contentEdit]: { size: { $set: "medium" } },
                    },
                  })
                )
              }}
            >
              <Icon source={TypeMajor} color="base" />
            </Button>
          </div>
          <div className="base-size">
            <Button
              pressed={"base" === content.size}
              onClick={() => {
                setState(
                  update(state, {
                    contents: {
                      [contentEdit]: { size: { $set: "base" } },
                    },
                  })
                )
              }}
            >
              <Icon source={TypeMajor} color="base" />
            </Button>
          </div>
          <div className="small-size">
            <Button
              pressed={"small" === content.size}
              onClick={() => {
                setState(
                  update(state, {
                    contents: {
                      [contentEdit]: { size: { $set: "small" } },
                    },
                  })
                )
              }}
            >
              <Icon source={TypeMajor} color="base" />
            </Button>
          </div>
          <div className="extraSmall-size">
            <Button
              pressed={"extraSmall" === content.size}
              onClick={() => {
                setState(
                  update(state, {
                    contents: {
                      [contentEdit]: { size: { $set: "extraSmall" } },
                    },
                  })
                )
              }}
            >
              <Icon source={TypeMajor} color="base" />
            </Button>
          </div>
        </ButtonGroup>
      </TextSizeWrapper>
      <p>Color</p>
      <Stack>
        <ButtonGroup>
          {colors.map(({ value, color }, index) => {
            return (
              <div key={index} style={{ textAlign: "center" }}>
                <div
                  className="ColorButton"
                  style={
                    value === content.color
                      ? { boxShadow: "0px 3px 0px 0px rgba(0,0,0,0.75)" }
                      : {}
                  }
                >
                  <Button
                    onClick={() => {
                      setState(
                        update(state, {
                          contents: {
                            [contentEdit]: { color: { $set: value } },
                          },
                        })
                      )
                    }}
                  >
                    <div
                      style={{ backgroundColor: `${color}` }}
                      className="ColorButtonContent"
                    />
                  </Button>
                </div>
                <span style={{ display: "block" }}>{value}</span>
              </div>
            )
          })}
        </ButtonGroup>
      </Stack>
      <p>Style</p>
      <ButtonGroup segmented>
        <Button
          pressed={"bold" === content.emphasis}
          onClick={(value) => {
            if ("bold" === content.emphasis) {
              setState(
                update(state, {
                  contents: {
                    [contentEdit]: { emphasis: { $set: null } },
                  },
                })
              )
            } else {
              setState(
                update(state, {
                  contents: {
                    [contentEdit]: { emphasis: { $set: "bold" } },
                  },
                })
              )
            }
          }}
        >
          B
        </Button>
        <Button
          pressed={"italic" === content.emphasis}
          onClick={(value) => {
            if ("italic" === content.emphasis) {
              setState(
                update(state, {
                  contents: {
                    [contentEdit]: { emphasis: { $set: null } },
                  },
                })
              )
            } else {
              setState(
                update(state, {
                  contents: {
                    [contentEdit]: { emphasis: { $set: "italic" } },
                  },
                })
              )
            }
          }}
        >
          I
        </Button>
      </ButtonGroup>
    </FormLayout>
  )
}
